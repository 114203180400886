import { useRouter } from 'next/router';
import SearchForm from '../shared/SearchForm';
import GridSearchResult from './GridSearchResult';
import FacetNavbar from '../shared/FacetNavbar';
import { retrieveFacetNavbarContent } from '../search';
import React, { useState, useEffect } from 'react';

const GridSearchResults = ({ results, numFound }) => {
    //console.log("Executing GridSearchResults.")
    if (!results) {
        return <div>Error; no results received.</div>;
    }

    const router = useRouter();
    const [facetNavbarContent, setFacetNavbarContent] = useState(null);
    useEffect(() => {
        (async () => {
            const facetNavbarContent = await retrieveFacetNavbarContent(router.query);
            setFacetNavbarContent(facetNavbarContent);
        })();
    }, [results]);

    // This array establishes the order of grid boxes, since it's a relatively arbitrary sort.
    const groupOrder = ['Programs', 'Studies', 'Data Packages', 'Analysis Reports', 'Questionnaires', 'Learning Reports', 'External Publications', 'Other Reports', 'Misc.'];
    // 'Programs' aren't returned by the query; included so the full list of search categories is present, in case MCC changes their minds
    // 'Misc.' is that the search returns for objects that don't have a searchCategory value established.

    return (
        <>
            <div className='row searchRow'>
                <p>Showing {numFound} results.</p>
                <SearchForm />
                {/* do not show FacetNavBar for the dashboard page */}
                {router.pathname.includes('products') && <FacetNavbar content={facetNavbarContent} />}
            </div>
            <div className='row searchRow'>
                {numFound > 0 ? (
                    groupOrder.map((groupLabel, i) => {
                        let group = results.find((group) => group.groupValue === groupLabel);
                        if (group && group !== null) {
                            const count = facetNavbarContent && facetNavbarContent[groupLabel] ? facetNavbarContent[groupLabel] : '?';
                            return <GridSearchResult group={group} key={i} count={count} />;
                        }
                    })
                ) : (
                    <div className='alert alert-light' role='alert'>
                        No results found.
                    </div>
                )}
            </div>
        </>
    );
};

export default GridSearchResults;
