import ListSearchResult from './ListSearchResult';
import SearchForm from '../shared/SearchForm';
import { ProductsPagination } from './ProductsPagination';
import { useRouter } from 'next/router';
import SortWithinPage from './SortWithinPage';
import SortAllResults from './SortAllResults';
import { retrieveFacetNavbarContent } from '../search';
import React, { useState, useEffect } from 'react';
import FacetNavbar from '../shared/FacetNavbar';
import SelectRowsPerPage from './SelectRowsPerPage';

const ListSearchResults = ({ results, numFound, sourcePath, totalPages, rows }) => {
    const router = useRouter();
    const [facetNavbarContent, setFacetNavbarContent] = useState(null);

    if (!results) {
        return <div>Error, no results received.</div>;
    }
    useEffect(() => {
        (async () => {
            const facetNavbarContent = await retrieveFacetNavbarContent(router.query);
            setFacetNavbarContent(facetNavbarContent);
        })();
    }, [results]);

    const page = parseInt(router.query.page || '1');
    const startIndex = Number(page - 1) * Number(rows) + 1;
    const endIndex = startIndex - 1 + Number(rows);
    const indexOfLast = numFound < endIndex ? numFound : endIndex;

    const sort = router.query.sort || 'score desc';
    const sortingParam = router.query.sortBy || '';
    const sortedResults = [...results].sort((a, b) => {
        if (typeof a[sortingParam] === 'string') {
            return a[sortingParam].localeCompare(b[sortingParam]);
        } else {
            return a[sortingParam] - b[sortingParam];
        }
    });

    return (
        <>
            <div className='row searchRow'>
                <p>
                    Showing {numFound > 0 ? startIndex + '-' + indexOfLast + ' of ' : ''} {numFound} results.
                </p>
                <SearchForm />
                {router.pathname.includes('products') && <FacetNavbar content={facetNavbarContent} />}
            </div>
            <div className='row searchRow'>
                <div className='col'>
                    <SelectRowsPerPage rows={rows} />
                </div>
                <div className='col'>
                    <SortAllResults sort={sort} />
                </div>
                <div className='col'>
                    <SortWithinPage sortBy={sortingParam} />
                </div>
                <div className='col align-self-end'>
                    <ProductsPagination
                        totalPages={totalPages}
                        page={page}
                        query={router.query}
                        sourcePath={sourcePath && sourcePath != '/' ? sourcePath + '/' : '/'}
                    />
                </div>
            </div>

            <div
                className='row searchRow'
                id='list-search-results'>
                <table className='table table-responsive table-bordered'>
                    <thead>
                        <tr>
                            <th
                                scope='col'
                                className='col-1'>
                                {' '}
                            </th>
                            <th
                                scope='col'
                                className='col-1'>
                                Cat.&nbsp;ID
                            </th>
                            <th
                                scope='col'
                                className='col-1'>
                                Eval.&nbsp;ID
                            </th>
                            <th
                                scope='col'
                                className='col-5'>
                                Title
                            </th>
                            <th
                                scope='col'
                                className='col-1'>
                                Version
                            </th>
                            <th
                                scope='col'
                                className='col-1'>
                                Product Type
                            </th>
                            <th
                                scope='col'
                                className='col-1'>
                                Status
                            </th>
                            <th
                                scope='col'
                                className='col-1'>
                                Assignments
                            </th>
                            <th
                                scope='col'
                                className='col-1'>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {numFound > 0 ? (
                            sortedResults.map((result, i) => (
                                <ListSearchResult
                                    result={result}
                                    index={i}
                                    key={result.product_version_id}
                                    sourcePath={sourcePath}
                                    page={page}
                                    rows={rows}
                                />
                            ))
                        ) : (
                            <tr align='center'>
                                <td colSpan={8}>No results found.</td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <ProductsPagination
                                    totalPages={totalPages}
                                    page={page}
                                    query={router.query}
                                    sourcePath={sourcePath && sourcePath != '/' ? sourcePath + '/' : '/'}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    );
};

export default ListSearchResults;
