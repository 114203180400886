import { useRouter } from 'next/router';
import Link from 'next/link';
import getConfig from 'next/config';
import styles from './GridSearchResult.module.css';

const GridSearchResult = ({ group, count }) => {
    const router = useRouter();
    const nextConfig = getConfig();
    const buildLink = (queryObject, group) => {
        let baseUrl = 'products?';
        for (const [key, value] of Object.entries(queryObject)) {
            if (key !== 'type') {
                baseUrl += `${key}=${value}&`;
            }
        }
        baseUrl += `search_category_facet=${group}&`;
        baseUrl += 'type=list';
        return baseUrl;
    };

    const handleViewGroup = (e) => {
        router.push(buildLink(router.query, e.target.value));
    };

    return (
        <div className='col-sm-6 col-md-4 mb-3'>
            <div className='card h-100'>
                <div className='card-body'>
                    <h5 className='d-inline-block card-title'>{group.groupValue}</h5>
                    <h6 className='d-inline-block card-subtitle text-muted'>{`${count} results`}</h6>
                    <ul className='list-group list-group-flush'>
                        {group.result.slice(0, 3).map((result) => {
                            return (
                                <li className='list-group-item' key={result.product_version_id}>
                                    <Link
                                        href={`/products/${result.product_version_id}`}
                                        
                                        className='text-decoration-none'>
                                        {result.dcterms_title ? result.dcterms_title : 'Untitled'}
                                    </Link>
                                    <br />
                                    <span className='text-secondary'>{result.dcterms_alternative ? result.dcterms_alternative : 'No Description'}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className='card-footer text-muted'>
                    <button className='btn btn-link' value={group.groupValue} onClick={handleViewGroup}>
                        View and filter all {group.groupValue} results
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GridSearchResult;
