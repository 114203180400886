import { useRouter } from 'next/router';
import { Fragment } from 'react';
import FacetValue from './FacetValue';
import FacetViewAll from './FacetViewAll';

const Facet = ({ facetField, facet, sourcePath, index }) => {
    //console.log("Executing Facet.")
    const router = useRouter();
    let expanded = false;
    if (index === 0 || router.query.hasOwnProperty(facetField)) {
        expanded = true;
    }
    function buildFacetLabel() {
        let facetLabel = facetField.replace('_facet', '').replaceAll('_', ' ').replace('dcterms', '').replace('imeta', '');
        if (facetLabel === 'location') {
            facetLabel = 'country';
        } else if (facetLabel === 'environment') {
            facetLabel = 'location type';
        } else if (facetLabel === 'solutions') {
            facetLabel = 'intervention types';
        } else if (facetLabel === 'rounds') {
            facetLabel = 'study phase';
        } else if (facetLabel === 'topic') {
            facetLabel = 'cross-sector themes';
        }
        return facetLabel;
    }
    if (Object.keys(facet).length === 0) {
        // Don't display facets that have zero values.
        return <Fragment />;
    }
    return (
        <div className='accordion-item'>
            <div className='accordion-header' id={'heading' + facetField}>
                <div
                    className={'accordion-button ' + (expanded ? '' : 'collapsed')}
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target={'#collapse' + facetField}
                    aria-expanded={expanded}
                    aria-controls={'collapse' + facetField}>
                    {buildFacetLabel()}
                </div>
            </div>
            <div id={'collapse' + facetField} className={'accordion-collapse collapse ' + (expanded ? 'show' : '')} aria-labelledby={'heading' + facetField} data-bs-parent='#facetAccordion'>
                <div className='accordion-body'>
                    <ul className='mccFacetUL'>
                        {Object.keys(facet).map((key, i) => (
                            <FacetValue key={'facet_' + facetField + '_' + i} facetValue={key} facetCount={facet[key]} field={facetField} sourcePath={sourcePath} />
                        ))}

                        <FacetViewAll field={facetField} sourcePath={sourcePath} limit={Object.keys(facet).length} />
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Facet;
