import { useRouter } from 'next/router';

const SortAllResults = ({ sort }) => {
    const router = useRouter();
    // Bizarre nonsense I have to build because there's no hook to remove a query parameter via router.
    // sortBy has to be removed so that top-level sort can trigger.
    let revisedQuery = {};
    const handleChange = (e) => {
        for (const [key, value] of Object.entries(router.query)) {
            if (key !== 'sortBy') {
                revisedQuery[key] = router.query[key];
            }
        }
        revisedQuery.sort = e.target.value;
        revisedQuery.page = 1;
        router.query = revisedQuery;
        router.push(router);
    };
    return (
        <>
            <label id='sortAll-label' htmlFor='sortAll' className='form-label'>
                Sort all
            </label>
            <select id='sortAll' value={sort} onChange={handleChange} className='form-select'>
                <option value='score desc'>Relevancy</option>
                <option value='product_id asc'>Catalog ID</option>
                <option value='imeta_evaluationId asc'>Evaluation ID</option>
                <option value='dcterms_title_sort asc'>Title</option>
                <option value='product_type asc'>Product Type</option>
                <option value='status asc'>Status</option>
            </select>
        </>
    );
};

export default SortAllResults;
