import { useRouter } from 'next/router';

const SortWithinPage = ({ sortBy }) => {
    const router = useRouter();
    const handleChange = (e) => {
        router.query.sortBy = e.target.value;
        router.push(router);
    };
    return (
        <>
            <label id='sortWithinPage-label' htmlFor='sortWithinPage' className='form-label'>
                Sort within page
            </label>
            <select id='sortWithinPage' value={sortBy} onChange={handleChange} className='form-select'>
                <option value=''></option>
                <option value='score'>Relevancy</option>
                <option value='product_version_id'>ID</option>
                <option value='dcterms_title'>Title</option>
                <option value='product_type'>Product Type</option>
                <option value='version_label'>Version</option>
                <option value='status'>Status</option>
            </select>
        </>
    );
};

export default SortWithinPage;
