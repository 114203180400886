import getConfig from 'next/config';
import { fetcher } from '../utils';

async function retrieveFacetNavbarContent(query) {
    let apiPath = 'products/facets/search_category_facet?';
    for (let [key, value] of Object.entries(query)) {
        if (key === 'type') {
            // ignore, type=list|grid is not relevant for this query, and produces an error upstream
        } else if (key !== 'search_category_facet' && Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                apiPath += `${key}=${value[i]}&`;
            }
        } else if (key !== 'search_category_facet') {
            if (key === 'q') {
                value = encodeURIComponent(value);
                apiPath += `${key}=${value}&`;
            } else {
                apiPath += `${key}=${value}&`;
            }
        }
    }
    apiPath = apiPath.substring(0, apiPath.length - 1);
    const nextConfig = getConfig();
    let data = await fetcher(`${nextConfig.publicRuntimeConfig.apiProxyBasePath}/${apiPath}`);
    if (data) {
        if (data.facet_counts && data.facet_counts.facet_fields && data.facet_counts.facet_fields.search_category_facet) {
            return data.facet_counts.facet_fields.search_category_facet;
        }
        console.error('api did not return expected content');
    }
    return null;
}

export { retrieveFacetNavbarContent };
