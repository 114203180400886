import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { getFaIcon } from '../../Icons';
const SearchForm = () => {
    //console.log("Executing SearchForm.")
    const router = useRouter();
    const [q, setQ] = useState(router.query.q ? router.query.q : '');
    const [type, setType] = useState(router.query.type ? router.query.type : 'list');
    const handleSubmit = (e) => {
        e.preventDefault();
        router.push(router.pathname + buildQueryString(q, type));
    };
    function buildQueryString(q, type) {
        console.log('Executing buildQueryString.');
        let queryString = '?';
        for (const [key, value] of Object.entries(router.query)) {
            //console.log("Evaluating " + key + " = " + value)
            if (key !== 'type' && key !== 'q') {
                queryString += `${key}=${value}&`;
            }
        }
        queryString += `type=${type}`;
        if (q !== '') {
            q = encodeURIComponent(q);
            queryString += `&q=${q}`;
        }
        return queryString;
    }

    const viewAll = (e) => {
        console.log('Executing viewAll.');
        e.preventDefault();
        setQ('');
        router.push(router.pathname + '?type=' + type);
    };
    const toggleToListView = (e) => {
        //console.log("Executing toggleToListView.")
        e.preventDefault();
        setType('list');
        router.push(router.pathname + buildQueryString(q, 'list'));
        // FIXME
        // MR 2021-12-08 This router.push is not ideal; but the setType wasn't actually doing anything.
        // Consider this a temporary fix.
        //setType('list')
    };
    const toggleToGridView = (e) => {
        //console.log("Executing toggleToGridView.")
        e.preventDefault();
        if (router.query.search_category_facet) {
            window.alert("Grid display disabled because you've already selected a search category.");
        } else {
            setType('grid');
            router.push(router.pathname + buildQueryString(q, 'grid'));
        }
        //setType('grid')
    };

    let listViewButton = (
        <a>
            {getFaIcon('listIcon', 'sm', null)}
            {'\u00A0'}List
        </a>
    );
    let gridViewButton = (
        <a>
            {getFaIcon('thIcon', 'sm', null)}
            {'\u00A0'}Grid
        </a>
    );
    if (type === 'list') {
        gridViewButton = (
            <a href='#' onClick={toggleToGridView} className='active-view-mode'>
                {getFaIcon('thIcon', 'sm', null)}
                {'\u00A0'}Grid
            </a>
        );
    } else if (type === 'grid') {
        listViewButton = (
            <a href='#' onClick={toggleToListView} className='active-view-mode'>
                {getFaIcon('listIcon', 'sm', null)}
                {'\u00A0'}List
            </a>
        );
    }
    let viewOptions = <span></span>;
    if (type === 'grid' && router.query.search_category_facet) {
        //if type is grid AND search_category_facet is present, hide the view options buttons
    } else {
        viewOptions = (
            <div>
                <span>View options:</span>
                {'\u00A0'}
                {'\u00A0'}
                {listViewButton}
                {'\u00A0'}
                {'\u00A0'}
                {gridViewButton}
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='col-6 align-self-end'>
                    <label htmlFor='q' className='form-label sr-only'>
                        Enter Query
                    </label>
                    <input onChange={(e) => setQ(e.currentTarget.value)} value={q} className='form-control' name='q' id='q' />
                </div>
                <div className='col-2 align-self-end'>
                    <button type='submit' className='btn btn-primary' onClick={handleSubmit}>
                        Go
                    </button>
                    {'\u00A0'}
                    <button type='button' className='btn btn-primary' onClick={viewAll}>
                        View All
                    </button>
                </div>
                <div className='col-4 align-self-end' id='view-options'>
                    {viewOptions}
                </div>
            </div>
        </form>
    );
};

export default SearchForm;
