import Facet from './Facet';
import { useEffect } from 'react';

const Facets = ({ facets, sourcePath, numFound }) => {
    //console.log("Executing Facets.")
    //console.log(facets)
    //Bootstrap accordiion require bootstrap js, use useEffect to import bootstrap js at client side (code runs first on server and then on client) to avoid "document is undefined" error. Source: https://stackoverflow.com/questions/67845378/how-can-i-use-bootstrap-5-with-next-js
    useEffect(() => {
        if (typeof document !== undefined) {
            (async() => {await import ("bootstrap/dist/js/bootstrap");})();
          }
    }, []);
    return (
        <div id='facets'>
            <h2>Facets</h2>
            <div className='accordion' id='facetAccordion'>
                {numFound > 0 ? (
                    Object.keys(facets.facet_fields).map((key, i) => <Facet facetField={key} facet={facets.facet_fields[key]} key={'facet_' + i} sourcePath={sourcePath} index={i} />)
                ) : (
                    <span>No results found.</span>
                )}
            </div>
        </div>
    );
};

export default Facets;
