import { useRouter } from 'next/router';
import styles from './FacetNavbar.module.css';
import Link from 'next/link';
import classNames from 'classnames';

const FacetNavbar = ({ content }) => {
    if (!content) {
        return <div></div>;
    }
    const router = useRouter();
    const buildLink = (facetValue) => {
        let baseUrl = 'products?';
        for (const [key, value] of Object.entries(router.query)) {
            if (key !== 'type' && Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    baseUrl += `${key}=${value[i]}&`;
                }
            } else if (key !== 'type') {
                baseUrl += `${key}=${value}&`;
            }
        }
        if (facetValue) {
            baseUrl = baseUrl.replace(/(search_category_facet=).*?(&)/, '');
            baseUrl += `search_category_facet=${facetValue}&`;
        } else {
            baseUrl = baseUrl.replace(/(search_category_facet=).*?(&)/, '');
        }
        baseUrl += 'type=list';
        return baseUrl;
    };
    const groupOrder = ['Programs', 'Studies', 'Data Packages', 'Analysis Reports', 'Questionnaires', 'Learning Reports', 'External Publications', 'Other Reports', 'Misc.'];
    return (
        <nav className={`${styles.nav} m-2 p-2`} aria-label='breadcrumb'>
            <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                    <Link
                        href={`${buildLink(null)}`}
                        className={classNames({
                            [styles.facetSelected]: !router.query.hasOwnProperty('search_category_facet') || router.query['search_category_facet'] === null,
                        })}>
                        
                            All Results
                        
                    </Link>
                </li>
                {groupOrder.map((groupLabel, i) => {
                    if (content[groupLabel]) {
                        return (
                            <li className='breadcrumb-item' key={i}>
                                <Link
                                    href={`${buildLink(groupLabel)}`}
                                    className={classNames({
                                        [styles.facetSelected]: router.query.hasOwnProperty('search_category_facet') && router.query['search_category_facet'] === groupLabel,
                                    })}>

                                    {groupLabel}({content[groupLabel]})
                                </Link>
                            </li>
                        );
                    }
                })}
            </ol>
        </nav>
    );
};

export default FacetNavbar;
