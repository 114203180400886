import Link from 'next/link';
import { forwardRef } from 'react';

function prevPath(sourcePath, page, query) {
    let link = sourcePath + '?';
    query.page = page;

    for (const [key, value] of Object.entries(query)) {
        switch (key) {
            case 'page':
                if (query.page > 1) {
                    link += '&page=' + (Number(query.page) - 1);
                } else {
                    link += '&page=' + Number(query.page);
                }
                break;
            default:
                link += '&' + key + '=' + encodeURIComponent(value);
        }
    }

    return link;
}

function nextPath(sourcePath, page, query) {
    let link = sourcePath + '?';
    query.page = page;

    for (const [key, value] of Object.entries(query)) {
        switch (key) {
            case 'page':
                link += '&page=' + (Number(query.page) + 1);
                break;
            default:
                link += '&' + key + '=' + encodeURIComponent(value);
        }
    }

    return link;
}

function nextNextPath(sourcePath, page, query) {
    let link = sourcePath + '?';
    query.page = page;
    if (typeof query == 'undefined') {
        link += 'page=' + (page + 2);
    } else {
        for (const [key, value] of Object.entries(query)) {
            switch (key) {
                case 'page':
                    link += '&page=' + (Number(query.page) + 2);
                    break;
                default:
                    link += '&' + key + '=' + encodeURIComponent(value);
            }
        }
    }
    return link;
}

function firstPath(sourcePath, page, query) {
    let link = sourcePath + '?';
    query.page = page;

    for (const [key, value] of Object.entries(query)) {
        switch (key) {
            case 'page':
                if (query.page > 1) {
                    link += '&page=1';
                } else {
                    link += '&page=' + Number(query.page);
                }
                break;
            default:
                link += '&' + key + '=' + encodeURIComponent(value);
        }
    }

    return link;
}

function lastPath(sourcePath, page, query, totalPages) {
    let link = sourcePath + '?';
    query.page = page;

    for (const [key, value] of Object.entries(query)) {
        switch (key) {
            case 'page':
                link += '&page=' + totalPages;
                break;
            default:
                link += '&' + key + '=' + encodeURIComponent(value);
        }
    }

    return link;
}

export const ProductsPagination = ({ totalPages, page, query, rows, startIndex, endIndex, sourcePath }) => {
    let nextPage = page + 1;
    let nextNextPage = page + 2;

    let firstPageClass = 'page-item';
    let lastPageClass = 'page-item';
    let prevPageClass = 'page-item';
    let nextPageClass = 'page-item';
    let nextNextPageClass = 'page-item';

    if (page >= totalPages) {
        nextPageClass = 'page-item d-none';
        nextNextPageClass = 'page-item d-none';
        lastPageClass = 'page-item d-none';
    }

    if (page + 1 >= totalPages) {
        nextNextPageClass = 'page-item d-none';
    }

    if (page == 1) {
        prevPageClass = 'page-item d-none';
        firstPageClass = 'page-item d-none';
    }

    return (
        <nav>
            <ul className='pagination'>
                <li className={firstPageClass}>
                    <Link href={`${firstPath(sourcePath, page, query)}`} className='page-link'>
                        First
                    </Link>
                </li>
                <li className={prevPageClass}>
                    <Link href={`${prevPath(sourcePath, page, query)}`} className='page-link'>
                        Previous
                    </Link>
                </li>
                <li className='page-item active' aria-current='page'>
                    <span className='page-link'>{page}</span>
                </li>
                <li className={nextPageClass}>
                    <Link href={`${nextPath(sourcePath, page, query)}`} className='page-link'>
                        {nextPage}
                    </Link>
                </li>
                <li className={nextNextPageClass}>
                    <Link href={`${nextNextPath(sourcePath, page, query)}`} className='page-link'>
                        {nextNextPage}
                    </Link>
                </li>
                <li className={nextPageClass}>
                    <Link href={`${nextPath(sourcePath, page, query)}`} className='page-link'>
                        Next
                    </Link>
                </li>
                <li className={lastPageClass}>
                    <Link
                        href={`${lastPath(sourcePath, page, query, totalPages)}`}
                        className='page-link'>
                        Last
                    </Link>
                </li>
            </ul>
        </nav>
    );
};
