import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { faRepeat } from '@fortawesome/pro-solid-svg-icons';
import {
    faHeart,
    faSearch,
    faUser,
    faTimesCircle,
    faThumbsUp,
    faDownload,
    faPen,
    faTrash,
    faTrashAlt,
    faWindowClose,
    faExclamation,
    faEye,
    faFrown,
    faInfoCircle,
    faCheckCircle,
    faShoppingCart,
    faPlus,
    faMinus,
    faExclamationTriangle,
    faWrench,
    faUndoAlt,
    faArrowUp,
    faArrowDown,
    faTh,
    faList,
} from '@fortawesome/free-solid-svg-icons';
function getIconMap(iconName) {
    switch (iconName) {
        case 'arrowUpIcon':
            return faArrowUp;
            break;
        case 'exclamationTriangleIcon':
            return faExclamationTriangle;
            break;
        case 'wrenchIcon':
            return faWrench;
            break;
        case 'trashAltIcon':
            return faTrashAlt;
            break;
        case 'trashIcon':
            return faTrash;
            break;
        case 'undoAltIcon':
            return faUndoAlt;
            break;
        case 'plusIcon':
            return faPlus;
            break;
        case 'minusIcon':
            return faMinus;
            break;
        case 'listIcon':
            return faList;
            break;
        case 'thIcon':
            return faTh;
            break;
        case 'arrowUpIcon':
            return faArrowUp;
            break;
        case 'arrowDownIcon':
            return faArrowDown;
            break;
        case 'searchIcon':
            return faSearch;
            break;
        case 'profileIcon':
            return faUser;
            break;
        case 'heartIcon':
            return faHeart;
            break;
        case 'infoCircleIcon':
            return faInfoCircle;
            break;
        case 'closeIcon':
            return faTimesCircle;
            break;
        case 'thumbsUpIcon':
            return faThumbsUp;
            break;
        case 'eyeIcon':
            return faEye;
            break;
        case 'downloadIcon':
            return faDownload;
            break;
        case 'editIcon':
            return faPen;
            break;
        case 'frownIcon':
            return faFrown;
            break;
        case 'repeatIcon':
            return faRepeat;
            break;
    }
}
config.autoAddCss = false;
export const getFaIcon = (iconName, size, color) => <FontAwesomeIcon icon={getIconMap(iconName)} size={size ? size : 'sm'} style={{ color: color ? color : '' }} />;
export const arrowUpIcon = <FontAwesomeIcon icon={faArrowUp} size='sm' style={{ color: '#3f51b5' }} />;
export const arrowDownIcon = <FontAwesomeIcon icon={faArrowDown} size='sm' style={{ color: '#3f51b5' }} />;
export const searchIcon = <FontAwesomeIcon icon={faSearch} size='sm' style={{ color: '#3f51b5' }} />;
export const profileIcon = <FontAwesomeIcon icon={faUser} style={{ color: '#3f51b5' }} />;
export const heartIcon = <FontAwesomeIcon icon={faHeart} style={{ color: '#3f51b5' }} />;
export const infoIcon = <FontAwesomeIcon icon={faInfoCircle} size='sm' style={{ color: '#3f51b5' }} />;
export const closeIcon = <FontAwesomeIcon icon={faTimesCircle} size='sm' style={{ color: '#3f51b5' }} />;
export const thumbsUpIcon = <FontAwesomeIcon icon={faThumbsUp} size='sm' style={{ color: '#3f51b5' }} />;
export const eyeIcon = <FontAwesomeIcon icon={faEye} size='sm' style={{ color: '#3f51b5' }} />;
export const downloadIcon = <FontAwesomeIcon icon={faDownload} size='sm' style={{ color: '#3f51b5' }} />;
export const editIcon = <FontAwesomeIcon icon={faPen} size='sm' style={{ color: '#3f51b5' }} />;
export const trashIcon = <FontAwesomeIcon icon={faTrash} size='sm' style={{ color: '#3f51b5' }} />;
export const trashAltIcon = <FontAwesomeIcon icon={faTrashAlt} size='sm' />;

export const frownIcon = <FontAwesomeIcon icon={faFrown} size='sm' style={{ color: '#3f51b5' }} />;

export const greenCheckIcon = <FontAwesomeIcon icon={faCheckCircle} size='sm' color='green' />;

export const windowCloseIcon = <FontAwesomeIcon icon={faWindowClose} size='sm' style={{ color: '#3f51b5' }} />;

export const exclamationIcon = <FontAwesomeIcon icon={faExclamation} size='sm' />;
export const wrenchIcon = <FontAwesomeIcon icon={faWrench} size='sm' style={{ color: '#3f51b5' }} />;
export const exclamationTriangleIcon = <FontAwesomeIcon icon={faExclamationTriangle} size='sm' style={{ color: '#3f51b5' }} />;

export const shoppingCartIcon = <FontAwesomeIcon icon={faShoppingCart} size='sm' style={{ color: '#3f51b5' }} />;
export const plusIcon = <FontAwesomeIcon icon={faPlus} size='sm' />;
export const minusIcon = <FontAwesomeIcon icon={faMinus} size='sm' />;
export const undoAltIcon = <FontAwesomeIcon icon={faUndoAlt} size='sm' />;

// Social Media Icons
export const faceBook = <FontAwesomeIcon icon={['facebook-square']} />;
export const twitter = <FontAwesomeIcon icon={['twitter-square']} />;
export const instagram = <FontAwesomeIcon icon={['instagram-square']} />;
export const youtube = <FontAwesomeIcon icon={['youtube-square']} />;
export const linkedIn = <FontAwesomeIcon icon={['linkedin']} />;
