import { useRouter } from 'next/router'
import Link from "next/link";

const limitToDisplay =10;
const FacetViewAll = ({field,sourcePath, limit}) => {
    //console.log("Executing FacetViewAll.")
    const router = useRouter()
    if(router.query.hasOwnProperty(field) || (limit < limitToDisplay)){
        return (<></>)
    }
    const link = buildViewAllLink(router.query,field,sourcePath)
    return (
        <li>
            <Link href={`${link}`}>view all</Link>
        </li>
    );   
}

function buildViewAllLink(query,field,sourcePath){
    //console.log("Executing buildAddFacetValueLink.")
    // When adding a new facet value, reset start to 1.
    let link = sourcePath + '/facets/' + field + '?'
    for(const [key, value] of Object.entries(query)){
        if(link.substr(link.length-1,1)!=='?'){
            link += '&'
        }
        switch(key){
            case "page":
                link += 'page=1'
                break;
            default:
                link += key + '=' + encodeURIComponent(value)
        }
    }
    return link;
}

export default FacetViewAll