import GridSearchResults from './grid/GridSearchResults';
import ListSearchResults from './list/ListSearchResults';
import Facets from './shared/Facets';
import { useRouter } from 'next/router';

const SearchResults = ({ results, facets, numFound, sourcePath, rows, totalPages }) => {
    if (!results) {
        return <div>Error; no results received.</div>;
    }
    //console.log(sourcePath);
    const router = useRouter();
    let displayType = router.query.type || 'list';
    if (router.query.search_category_facet) {
        displayType = 'list';
    }
    return (
        <div className='row'>
            <div className='col-2'>
                <Facets
                    facets={facets}
                    sourcePath={sourcePath ? sourcePath : '/products'}
                    numFound={numFound}
                />
            </div>
            <div className='col-10'>
                {displayType === 'grid' ? (
                    <GridSearchResults
                        results={results}
                        numFound={numFound}
                    />
                ) : (
                    <ListSearchResults
                        results={results}
                        numFound={numFound}
                        sourcePath={sourcePath ? sourcePath : '/products'}
                        totalPages={totalPages}
                        rows={rows}
                    />
                )}
            </div>
        </div>
    );
};

export default SearchResults;
