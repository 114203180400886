import { forwardRef } from 'react';
import { hideModal } from '../utils';
const DeleteConfirmationDialog = forwardRef((props, ref) => {
    const { title, onConfirm, children } = props;
    return (
        <div className='modal fade' ref={ref} tabIndex='-1'>
            <div className='modal-dialog  modal-dialog-centered'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title' id='staticBackdropLabel'>
                            {title}
                        </h5>
                        <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                    </div>
                    <div className='modal-body'>{children}</div>
                    <div className='modal-footer'>
                        <button type='button' className='btn btn-secondary' onClick={() => hideModal(ref)}>
                            No
                        </button>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={() => {
                                hideModal(ref);
                                onConfirm();
                            }}>
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default DeleteConfirmationDialog;
