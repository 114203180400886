import { useRouter } from 'next/router';

const SelectRowsPerPage = ({ rows }) => {
    const router = useRouter();
    // Bizarre nonsense I have to build because there's no hook to remove a query parameter via router.
    // sortBy has to be removed so that top-level sort can trigger.
    let revisedQuery = {};
    const handleChange = (e) => {
        for (const [key, value] of Object.entries(router.query)) {
            if (key !== 'rows') {
                revisedQuery[key] = router.query[key];
            }
        }
        revisedQuery.rows = e.target.value;
        revisedQuery.page = 1;
        router.query = revisedQuery;
        router.push(router);
    };
    return (
        <>
            <label
                id='sortWithinPage-label'
                htmlFor='rows'
                className='form-label'>
                Products per page
            </label>
            <select
                id='rows'
                value={rows}
                onChange={handleChange}
                className='form-select'>
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='500'>500</option>
            </select>
        </>
    );
};

export default SelectRowsPerPage;
